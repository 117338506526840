<script>
import ProductResources from '~/components/products/ProductResources.vue';
import BaseRecommendations from '~/components/base/BaseRecommendations.vue';
import { usePillarStore } from '~/store/PillarStore';
import productFamilyResources from '../../../json/languages/en/en.productfamily.resources.json';

export default {
  setup() {
    const pillarStore = usePillarStore();
    return {
      pillarStore,
    };
  },
  components: {
    ProductResources,
    BaseRecommendations
  },
  data() {
    return {
      isArrow: false,
      productsData: null,
      parsedProducts: null,
      prodFam: null,
      items: null,
    };
  },
  mounted() {
  },
  computed: {
    ProductFamily() {
      let fams = this.$tm('productFamilies');
      const route = useRoute();
      var paths = route.path.split('/');
      var prodFamUrl = paths.pop();
      var entryValues = Object.values(fams);
      var family = entryValues.find((pf) => this.$rt(pf.productFamilyURL) === prodFamUrl);
      if (family) {
        return family;
      }
      else return null;
    },
    Terms() {
      let terms = this.$tm('extraTerms');
      return terms;
    },
    Title() {
      if (this.ProductFamily === null) {
        return '';
      }

      return this.$rt(this.ProductFamily.productFamily).replace(/[™®©]/g, '<sup>$&</sup>');
    },
    Description() {
      if (this.ProductFamily === null || this.ProductFamily.pfDescription === null) {
        return '';
      }
      return this.$rt(this.ProductFamily.pfDescription);
    },
    Resources() {
      if (this.ProductFamily === null) {
        return [];
      }
      var curLocale = useI18n().locale.value;
      var tmp = this.$tm('productFamilyResources');
      var resources = [];
      //need to get english resources first.
      var enResources = productFamilyResources.productFamilyResources.filter(
        (element) => parseInt(element.cspID_fk) === parseInt(this.ProductFamily.pfID)//only ones that are not en
      )
      if (curLocale !== 'en') {
        //need to get locale specific resources and add the english resources to them
        resources = tmp.filter(
          (element) => (parseInt(element.cspID_fk) === parseInt(this.ProductFamily.pfID)) && (element.filename !== null || element.videoURL !== null)//only ones that are not en
        );
      }

      if (curLocale === 'en')
        resources = enResources;
      else {
        enResources.forEach((en) => {
          var found = resources.find((re) => re.libID_fk === en.libID_fk);
          if (!found) {
            resources.push(en);
          }
        })
      }

      const resourceTerm = this.Terms.find((element) => this.$rt(element.mlVar) === 'resources');
      resources.title = resourceTerm.mltText;
      return resources;
    },
    Products() {
      if (this.ProductFamily) {
        var allProducts = this.$tm('products');
        var familyProducts = allProducts.filter((element) => element.pfID_fk === this.ProductFamily.pfID && element.isModel === 0);
        const productTerm = this.Terms.find((element) => this.$rt(element.mlVar) === 'products');
        let products = {
          "title": this.ProductFamily.productFamily,
          "items": familyProducts
        };
        return products;
      }
      else return [];
    },
    BannerUri(prodFam) {
      return '/images/productfamilies/' + this.$rt(prodFam.ProductFamily.productFamilyURL) + '-hero-' + prodFam.ProductFamily.productFamilyHero + '.webp';
    },
  },
  methods: {

    handleHover(item) {
      item.isArrow = true;
    },
    handleLeave(item) {
      item.isArrow = false;
    },
  },
};
</script>
<template>
  <div v-if="ProductFamily" class="products">
   <!--  <Head v-if="ProductFamily !== null">
      <Title>{{ProductFamily.productFamilyPageTitle === null ? $rt(ProductFamily.productFamily) : $rt(ProductFamily.productFamilyPageTitle) }}</Title>
      <Meta name="description" :content="ProductFamily.productFamilyMetaDesc === null ? $rt(ProductFamily.productFamily) : $rt(ProductFamily.productFamilyMetaDesc)" />
    </Head> -->
    <div class="central-banner" :style="'background-image: url(' + BannerUri + ')'">
      <div class="central-banner-content">
        <div class="central-topic" v-if="ProductFamily !== null">{{ ProductFamily.productTitle }}</div>
        <div class="central-title" v-if="ProductFamily !== null" v-html="Title"></div>
        <div class="banner-text" v-html="Description"></div>
      </div>
    </div>


  </div>

  <div v-if="ProductFamily !== null">
    <BaseRecommendations :items="Products" />
  </div>

  <div class="central-outer-container">
    <ProductResources v-if="ProductFamily !== null && Resources.length > 0" :items="Resources" />
  </div>

</template>
<style scoped>
.central-banner {
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}

.central-banner-content {
  display: flex;
  align-items: left;
  align-self: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
}

.central-outer-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0px auto 0;
  max-width: 1200px;
  width: 100%;
}

.banner-text {
  font-size: 20px;
  max-width: 550px;
  line-height: 1.5;
}

.central-title {
  font-weight: 900;
  font-size: 55px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.central-topic {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0.8em;
  font-size: 1rem;
  color: inherit;
  display: block;
}

@media only screen and (max-width: 700px) {
  .central-title {
    font-size: 30px;
    font-weight: 900;
  }

  .central-banner {
    height: 220px;
  }
}

@media only screen and (max-width: 950px) {
  .central-box {
    grid-template-columns: 1fr 1fr;
  }

  .central-banner-content {
    width: 90%;
  }
}

@media only screen and (max-width: 700px) {
  .central-box {
    grid-template-columns: 1fr;
  }
}

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
